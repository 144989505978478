<template>
  <div id="app">
    <div :class="{'main_wrapper':is_in_main(), 'pages_wrapper': !is_in_main()}">
      <nav_panel v-if="!is_in_referal()"
                 style="position: fixed; z-index: 3; top: 0; width: 100%; "/>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
      <ScrollToTop/>
    </div>
  </div>
</template>

<script>

import nav_panel from "./components/elements/common/Nav";
import ftr_panel from "./components/elements/common/Ftr";
import ScrollToTop from "./components/elements/common/ScrollToTop";

import NewArchNoMobile from "./components/elements/common/NewArchNoMobile";

export default {
  components: {
    nav_panel,
    ftr_panel,
    ScrollToTop,
    NewArchNoMobile,
  },
  metaInfo: {
    htmlAttrs: {
      lang: 'ru',
      amp: true
    },
  },

  data() {
    return {}
  },

  methods: {
    is_in_main() {
      return this.$router.history.current["path"] === "/" || this.$router.history.current["path"].slice(0, 14) === '/func-redirect';
    },
    is_in_referal() {
      return this.$router.history.current["path"].slice(0, 8) === '/referal' || this.$router.history.current["path"].slice(0, 5) === '/play'
    },
  },
}

</script>

<style lang="scss">

@import "assets/scss/fonts";
@import "assets/scss/mixins";
@import "assets/scss/vars";
@import "assets/scss/animations";

html {
  @include spacing-zero();
}

body {
  @include flex-column();
  @include spacing-zero();
  overflow-x: auto;
  height: 100%;
  background: $bg-color;
}

// FADE ACTIVATE

.fade-enter, .fade-leave-to {
  opacity: 0.99999999;
}

.fade-enter-active, .fade-leave-active {
  transition: all .800ms ease-in;
}

// WRAPPERS

.main_wrapper {
  @include flex-column();
  overflow-x: hidden;
  opacity: 0.9999999;
  background: $bg-color;
  z-index: 1;
  min-width: $m-width;
}

.pages_wrapper {
  @include flex-column();
  overflow: hidden;
  background: $bg-color no-repeat;
  background-size: cover;
  min-width: $m-width;
}

.noMobile {
  min-width: auto;
  min-height: auto;
}

.content {
  flex: 1 0 auto;
}

// TOOLTIP SECTION STYLING

.v-popper--theme-LkToolTips, v-popper--theme-LkToolTips-cl, v-popper--theme-LkToolTips-top-withPd {
  z-index: 500 !important;
}

.v-popper--theme-GalleryToolTips-top {
  z-index: 1000 !important;
}

.v-popper--theme-LkToolTips .v-popper__inner, .v-popper--theme-LkToolTips-cl .v-popper__inner {

  @include flexible-column;
  text-align: center;
  width: auto;
  max-width: 150px;

  @include desctiption-0-8;
  background: #1c1c1d;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  color: $not-bright-white;

  span {
    color: #00ff00;
  }
}

.v-popper--theme-LkToolTips-top-withPd {
  .v-popper__inner {

  }
}

.v-popper--theme-GalleryToolTips-top-moreWidth {
  .v-popper__inner {
    width: auto;
    max-width: 300px;
  }
}

.v-popper--theme-LkToolTips .v-popper__arrow, .v-popper--theme-LkToolTips-cl .v-popper__arrow {
  border-color: #ffffff;
}

// SCROLL BAR SECTION

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #666666;
  border: 0px none #666666;
  border-radius: 42px;
}

::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

::-webkit-scrollbar-thumb:active {
  background: #666666;
}

::-webkit-scrollbar-track {
  background: #333333;
  border: 0px none #9a9a9a;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #333333;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
