import SquareFill from "../components/preloaders/SquareFill";

export const PreloaderMixin = {
    methods: {
        __preloaderStart(container, bgColor) {
            this.$preloaders.open({
                    container: container,
                    "component": SquareFill,
                    "overlayStyle": {
                        "backgroundColor": bgColor,
                        "opacity": 1,
                        "--bg-color": bgColor
                    },
                }
            );
        },

        preloaderStartModal(container, bgColor = "#202023") {
            this.__preloaderStart(container, bgColor)
        },

        preloaderStartLk(container, bgColor = "#1c1c1d") {
            this.__preloaderStart(container, bgColor)
        },

        preloaderStartUnblessed(container, bgColor = "#101010") {
            this.__preloaderStart(container, bgColor)
        },

        preloaderStartCustomColor(container, bgColor) {
            this.__preloaderStart(container, bgColor)
        },

        preloaderStop(container) {
            this.$preloaders.close({
                container: container,
            })
        },
    }
}