import Vue from 'vue'
import App from './App.vue'

import VuePreloaders from 'vue-preloaders'
import VueLazyload from 'vue-lazyload'
import VTooltip from "v-tooltip";
import VueWow from 'vue-wow'
import router from './router'
import VModal from 'vue-js-modal'

import VScrollLock from 'v-scroll-lock'

import infiniteScroll from 'vue-infinite-scroll'

import 'v-tooltip/dist/v-tooltip.css'
import 'vue-preloaders/dist/vue-preloaders.css'
import 'animate.css'

import VueMeta from "vue-meta";


Vue.config.productionTip = false

Vue.use(VueWow)
Vue.use(VModal)
Vue.use(VuePreloaders)
Vue.use(VScrollLock)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})


Vue.use(VueLazyload, {
    lazyComponent: true,

    // INTERSECTION OBSERVER -> эта опция может увеличить производительность при большом количестве лэзи
    // observer: true,
    // observerOptions: {
    //     rootMargin: '0px',
    //     threshold: 0.5
    // }
});

Vue.use(infiniteScroll)

Vue.use(VTooltip,
    {
        themes: {
            'LkToolTips': {
                triggers: ['hover', 'focus'],
                autoHide: true,
                skipTransition: true,
                eagerMount: true,
                placement: 'right',
                delay: {
                    show: 0,
                    hide: 0,
                },
            },
            'LkToolTips-bot' : {
                '$extend': 'LkToolTips',
                placement: 'bottom',
            },
            'LkToolTips-top-withPd' : {
                '$extend': 'LkToolTips-bot',
                placement: 'top'
            },
            'GalleryToolTips-top': {
                '$extend': 'LkToolTips-top-withPd'
            },
            'GalleryToolTips-top-moreWidth': {
                '$extend': 'GalleryToolTips-top'
            }
        }
    })

import {CheckAccountTypeMixin} from "./mixins/CheckAccountTypeMixin";

// Отдельный экземпляр под шину событий.
// export const eventBus = new Vue()

new Vue({
    mixins: [CheckAccountTypeMixin],
    data() {
        return {
            player_data: null,
            scrollPosition: 0,
        }
    },
    methods: {
        redirect(link) {
            if (this.$router.history.current["path"] !== link) {
                this.$router.replace(link)
            }
        },
        getPlayerData() {
            return this.player_data
        },

        setPlayerData(data) {
            this.player_data = data
        },

        // Методы для получения позиции скроллера.
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
        getScrollPosition() {
            return this.scrollPosition
        },

        // Метод обновления даты
        update_PlayerData() {
            return require('axios').get('/api/player?statuses=true&roles=true&permissions=true').then(
                data => {
                    localStorage.setItem('player', JSON.stringify(data.data))
                    this.$root.setPlayerData(data.data)
                }
            ).catch(err => {
                // Если мы не авторизованы то мы получим ошибку 401
                console.log(err)
                if (err.response.status === 401) {
                    localStorage.removeItem('player')
                    this.$root.setPlayerData(null);
                    this.$root.redirect('/')
                }
            })
        },

    },

    created() {
        window.addEventListener('scroll', this.updateScroll);
        if (localStorage.getItem('player')) {
            try {
                this.player_data = JSON.parse(localStorage.getItem('player'))

            } catch (e) {
                localStorage.removeItem('player')
            }
        }
    },

    router,
    render: h => h(App)
}).$mount('#app')



