<template>
    <div>
        <footer>
<!--            <div class="footer_greatray_link">-->
<!--                <a @click="$root.redirect('/')">GreatRay</a>-->
<!--            </div>-->
            <div class="footer_links">
                <ul>
                    <li><a href="#">Правила</a></li>
                    <li><a href="#">Команда проекта</a></li>
                    <li><a href="#">Обратная связь</a></li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "ftr_panel"
}
</script>

<style scoped>

footer {
    /*border: 1px solid #454647;*/
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    /*flex: 0 0 33.3%;*/
    width: 100%;
    height: 60px;
    background: rgba(16, 16, 16, 0.81);
}



.footer_links ul {
    flex: 0 0 100vw;
    /*padding: 1.25rem;*/
    text-align: center;
    align-items: center;
}

.footer_links ul li {
    list-style-type: none;
    display: inline-block;
    padding: 0 50px 0 50px;
}

.footer_links ul li a {
    font-family: Roboto_medium;
    font-size: 0.8em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.05em;
    text-align: left;
    color: #8a8f98;
    text-decoration: none;
}

.footer_links ul a:hover {
    text-decoration: none;
}


.footer_greatray_link a {
    text-decoration: none;
    color: #8a8f98;
    font-family: Roboto-regular;
    font-size: 0.9em;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 1px;
    line-height: normal;
    text-align: left;
}
</style>
