<template>
    <div class="wrapper">
    <span class="loader">
      <span class="loader-inner"></span>
    </span>
    </div>
</template>

<script>

export default {
    name: 'SquareFillLk',
};
</script>

<style lang="scss" scoped>

@import "preloaders";

@include preloader-square-fill();

</style>