export const CheckAccountTypeMixin = {
    methods: {
        isModerator() {
            if (this.player_data === null)
                return false
            let res = false
            for (let i in this.player_data.luckperms) {
                this.player_data.luckperms[i].statuses.forEach(status => {
                    if (status.status.price < 0)
                        res = true
                })
                if (res)
                    return res
            }
            return res
        },

        getServersModerator() {
            if (this.player_data === null)
                return []
            let checks = ['moder', 'help', 'adm']
            let res = []
            for (let i in this.player_data.luckperms) {
                let iss = false
                let can_ban = false
                let can_tempban = false
                this.player_data.luckperms[i].statuses.forEach(status => {
                    checks.forEach(check => {
                        if (status.status.lp_group.indexOf(check) !== -1)
                            iss = true
                    })
                    if (status.status.lp_group.indexOf('supermoder') !== -1 || status.status.lp_group.indexOf('adm') !== -1)
                        can_ban = true
                    if (status.status.lp_group.indexOf('moder') !== -1)
                        can_tempban = true
                })
                if (iss)
                    res.push({
                        server: i,
                        can_ban: can_ban,
                        can_tempban: can_tempban
                    })
            }
            return res
        },

        isAdmin() {
            if (this.player_data === null || this.player_data.roles == null)
                return false
            let res = false
            this.player_data.roles.forEach(role => {
                if (role.name === 'admin')
                    res = true
            })
            return res
        },

        isServerAdmin() {
            if (this.player_data === null || this.player_data.roles == null)
                return false
            let res = false
            this.player_data.roles.forEach(role => {
                if (role.name === 'gameadmin')
                    res = true
            })
            return res
        },

        isImitator() {
            return this.player_data !== null && this.player_data.imitator !== null
        },

    }
}