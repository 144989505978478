<template>

    <div class="auth-window">
        <div class="block" v-if="!is_forgotpwd">
            <p>Авторизация</p>
            <button class="auth-btn" @click="go_login()">Вход</button>
            <div class="entry-block" :class="{'show': is_login}">
                <input type="text" class="inputz" id="Entryblock_input"
                       placeholder="Логин / Email"
                       v-model="username"
                       autocomplete="on"
                       v-on:keyup.enter="login"
                >
                <input type="password" class="inputz" id="Entryblock_pwd"
                       placeholder="Пароль"
                       v-model="password"
                       autocomplete="on"
                       v-on:keyup.enter="login"
                >
                <div v-if="errors != null && errors._all !== undefined">
                    <div class="failed" v-for="err in errors._all">{{ err }}</div>
                </div>

                <button class="enter-btn" v-if="is_login" @click="login()">Войти</button>
                <button class="fogot-btn" v-if="is_login" @click="go_forgotPwd()">Забыл пароль
                </button>
            </div>
            <button class="register-btn" @click="go_reg()">Регистрация</button>
            <div class="register-block" :class="{'show': is_reg}">
                <input type="text" class="inputz" id="Registerblock_login"
                       placeholder="Логин" v-model="reg_username" v-on:keyup.enter="register">
                <div v-if="errors_in_reg != null && errors_in_reg.name !== undefined">
                    <div class="failed" v-for="err in errors_in_reg.name">{{ err }}</div>
                </div>
                <input type="password" class="inputz"
                       placeholder="Пароль" v-model="reg_password" v-on:keyup.enter="register">
                <div v-if="errors_in_reg != null && errors_in_reg.password !== undefined">
                    <div class="failed" v-for="err in errors_in_reg.password" style="width: 100%">{{ err }}</div>
                </div>
                <input type="password" class="inputz"
                       placeholder="Повторите пароль" v-model="reg_confirm_password" v-on:keyup.enter="register">
                <div class="failed" v-if="reg_confirm_pwd_check != null &&
                    reg_confirm_pwd_check === false" style="width: 100%">Пароли не совпадают, повторите ввод.
                </div>
                <input type="text" class="inputz" id="Registerblock_email"
                       placeholder="Email" v-model="reg_email" v-on:keyup.enter="register">
                <div v-if="errors_in_reg != null && errors_in_reg.email !== undefined" style="width: 100%">
                    <div class="failed" v-for="err in errors_in_reg.email">{{ err }}</div>
                </div>

                <div v-if="errors_in_reg != null && errors_in_reg._all !== undefined" style="width: 100%">
                    <div class="failed" v-for="err in errors_in_reg._all">{{ err }}</div>
                </div>

                <div class="checkbox-block">
                    <input type="checkbox" id="checkbox" class="signup-checkbox"
                           v-model="rules_checked">
                    <label for="checkbox" class="checkbox-label">
                        Я <a href="/rules" target="_blank"
                             class="link-label">прочитал(а)</a>
                        и согласен с правилами проекта.</label>
                </div>
                <button class="sign-in-up-btn" v-if="is_reg" @click="register()">
                    Зaрегистрироваться
                </button>
            </div>
            <div class="logo">GrEATraY {{currentYear}}</div>
        </div>
        <div class="block" v-if="is_forgotpwd">
            <div v-if="!send_success">
                <p>Забыли пароль?</p>
                <div class="entry-block show">
                    <input type="text" class="inputz "
                           placeholder="Email"
                           v-model="forbidden_email" autocomplete="on" v-on:keyup.enter="forgotPwd">
                    <div v-if="errors_in_fbdd != null && errors_in_fbdd.email !== undefined">
                        <div class="failed" v-for="err in errors_in_fbdd.email">{{ err }}</div>
                    </div>
                    <div v-if="errors_in_fbdd != null && errors_in_fbdd._all !== undefined">
                        <div class="failed" v-for="err in errors_in_fbdd._all">{{ err }}</div>
                    </div>
                    <button class="sign-in-up-btn" @click="forgotPwd()">Восстановить пароль
                    </button>
                </div>
            </div>

            <div class="success" v-else>
                <p>Инструкции по восстановлению пароля, были высланы на указанный e-mail.</p>
            </div>

        </div>
    </div>
</template>

<script>

import {PreloaderMixin} from "../../mixins/PreloaderMixin";
import {RegisterInputMixin} from "../../mixins/RegisterInputMixin";

export default {
    name: "AuthorizeModal",
    mixins: [PreloaderMixin, RegisterInputMixin],
    props: ["modalRedirect"],

    data() {
        return {
            is_login: false,
            is_reg: false,
            is_forgotpwd: false,


            username: "",
            password: "",

            forbidden_email: "",
            errors_in_fbdd: null,
            send_success: false,

            reg_username: "",
            reg_password: "",
            reg_confirm_password: "",
            reg_email: "",
            reg_confirm_pwd_check: null,
            errors_in_reg: null,
            rules_checked: false,


            login_failed: false,
            errors: null,
            process: false,

            currentYear: null

        }
    },
    methods: {
        go_reg() {
            this.is_reg = true
            this.is_login = false
        },
        go_login() {
            this.is_reg = false
            this.is_login = true
        },

        go_forgotPwd() {
            this.is_reg = false
            this.is_login = false
            this.is_forgotpwd = true
        },

        forgotPwd() {
            if (this.process)
                return
            this.process = true
            this.preloaderStartModal('.auth-window')
            require('axios').post('/api/auth/send_reset', {
                "email": this.forbidden_email
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {
                this.send_success = true
                this.process = false
                this.preloaderStop('.auth-window')
            }).catch(err => {
                console.log(err.response.status)
                this.errors_in_fbdd = err.response.data.errors
                console.log(err.response.data.errors)
                this.preloaderStop('.auth-window')

                if (this.errors_in_fbdd == null) {
                    this.errors_in_fbdd = {
                        _all: [
                            'Внутренняя ошибка сервера. Повторите запрос позднее'
                        ]
                    }
                }
                this.process = false
            })
        },

        login() {
            if (this.process)
                return
            if (this.password.length === 0 || this.username.length === 0) {
                this.errors = {
                    _all: [
                        'Вы не ввели все необходимые данные для авторизации.'
                    ]
                }
                return
            }
            this.process = true
            this.preloaderStartModal('.auth-window')

            require('axios').post('/api/auth/login', {
                "name": this.username,
                "password": this.password,
                "remember": true
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {
                localStorage.setItem('player', JSON.stringify(data.data))
                this.$root.setPlayerData(data.data)
                this.preloaderStop('.auth-window')
                this.$emit('close');
                try {
                    if (window.PasswordCredential) {
                        const passwordCredential = new PasswordCredential({
                            id: this.username,
                            password: this.password,
                        })
                        navigator.credentials.store(passwordCredential)
                    }
                } catch (e) {
                    this.preloaderStop('.auth-window')
                    console.log(e)
                }
                this.process = false
            }).catch(err => {
                console.log(err.response.status)
                this.errors = err.response.data.errors
                console.log(err.response.data.errors)
                this.preloaderStop('.auth-window')
                if (this.errors == null) {
                    this.errors = {
                        _all: [
                            'Внутренняя ошибка сервера. Повторите запрос позднее'
                        ]
                    }
                }
                this.process = false
            })
        },
        register() {
            if (this.process)
                return
            this.process = true
            this.errors_in_reg = this.check_register_input(this.reg_email, this.reg_username, this.reg_password)
            let go = this.errors_in_reg == null

            if (this.reg_password !== this.reg_confirm_password) {
                this.reg_confirm_pwd_check = false
                go = false
            } else {
                this.reg_confirm_pwd_check = true
            }
            if (!this.rules_checked) {
                this.errors_in_reg = {
                    _all: [
                        'Вы не согласны с правилами проекта.'
                    ]
                }
                go = false
            }

            if (!go) {
                this.process = false
                return
            }

            this.preloaderStartModal('.auth-window')

            require('axios').post('/api/auth/register', {
                "email": this.reg_email,
                "password": this.reg_password,
                "name": this.reg_username,
            }, {'Accept': "application/json", 'content-type': "application/json"}).then(data => {

                localStorage.setItem('player', JSON.stringify(data.data))
                this.$root.setPlayerData(data.data)
                this.preloaderStop('.auth-window')
                this.$emit('close');
                try {
                    if (window.PasswordCredential) {
                        const passwordCredential = new PasswordCredential({
                            id: this.reg_username,
                            password: this.reg_password,
                        })
                        navigator.credentials.store(passwordCredential)
                    }
                } catch (e) {
                    console.log(e)
                    this.preloaderStop('.auth-window')
                }
                this.process = false
            }).catch(err => {
                console.log(err.response.status)
                this.errors_in_reg = err.response.data.errors
                console.log(err.response.data.errors)
                this.preloaderStop('.auth-window')
                if (this.errors_in_reg == null) {
                    this.errors_in_reg = {
                        _all: [
                            'Внутренняя ошибка сервера. Повторите запрос позднее'
                        ]
                    }
                }
                this.process = false
            })
        },

        showRegisterOnRedirect() {
            this.is_reg = true
        },

        showFogotPwdOnRedirect() {
            this.is_forgotpwd = true
        },

        checkRedirectProp() {
            switch (this.modalRedirect) {
                case 'register':
                    this.showRegisterOnRedirect()
                    break;
                case 'forgotpwd':
                    this.showFogotPwdOnRedirect()
                    break;
            }
        }
    },
    mounted() {
        this.checkRedirectProp()

        this.currentYear = new Date().getFullYear()
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";
@import "src/assets/scss/vars";

@include in-page-preloader();

.auth-window {
    @include flex-column();
    padding: 47px 160px 47px;
    border-radius: 5px;
    background-color: $shitty-hex-gray;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.13);
    height: auto;
}

.block {
    @include flex-column();
    align-items: center;
    margin-top: -20px;
    font-family: $main-font;
    height: auto;

    p {
        text-align: center;
        margin-bottom: 14px;
        width: 200px;
        font-size: 1.3em;
        color: white;
        letter-spacing: 0.5px;
    }

    button {
        width: 336px;
        height: 48px;
        margin: 7px 0;
        border-radius: 4px;
        border: none;
        font-family: $main-font;
        outline: none;
        transition: all 0.5s ease;

        &:active {
            transform: scale(1.1);
        }

    }
}

@mixin shitty-shadow-transition() {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 1;
    -webkit-box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    -moz-box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
}

.auth-btn {
    @include auth-w-buttons($shitty-purple);

    &:hover {
        @include shitty-shadow-transition();
    }
}


.register-btn, .enter-btn, .fogot-btn, .sign-in-up-btn {
    @include auth-w-buttons($shitty-dark-gray);

    &:hover {
        @include shitty-shadow-transition();
    }
}


.logo {
    @include logos();
}

@mixin o-h-o-m() {
    transition: max-height .8s ease-in-out, padding .5s ease-in-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    max-height: 0;
}

.entry-block {
    @include flexible-column();
    @include o-h-o-m();
}

.inputz {
    width: 336px;
    height: 43px;
    border-radius: 10px;
    margin: 5px 0;
    background-color: rgb(31, 32, 35);
    border: 1px solid rgb(38, 44, 57);
    outline: none;
    color: white;
    text-align: center;

    &::placeholder {
        font-size: 1.1em;
        @include text-normalize();
        text-align: center;
        letter-spacing: 0.38px;
        color: $smooth-description-text;
        font-family: AventirN;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid rgb(38, 44, 57);
    -webkit-box-shadow: 0 0 0 1000px rgb(31, 32, 35) inset;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;

}

@mixin o-h-show() {
    opacity: 1;
    height: auto;
}

.entry-block.show {
    @include o-h-show();
    max-height: 230px;
}


.register-block {
    @include flexible-column();
    @include o-h-o-m();
}

.register-block.show {
    @include o-h-show();
    max-height: 350px;
}


.checkbox-block {
    @include flexible();
    padding: 20px 0 10px 0;

}

.signup-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@mixin lbls {
    font-size: 0.9em;
    @include text-normalize();
    letter-spacing: 0.38px;
    color: #c0c0c3;
    text-align: start;
}

.checkbox-label {
    @include lbls();
    margin-left: 3px;
    text-align: left;
}

.link-label {
    @include lbls();
    font-size: 0.9em;
    color: #e22f2f;
}

.failed {
    @include flexible();
    @include lbls();
    font-size: 0.9em;
    color: #e22f2f;
}

</style>
