<template>
    <a @click="scrollTop" v-show="visible" class="bottom-right">
      <div class="blink">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white"
             class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
        </svg>
      </div>
    </a>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                speed: 10,
            }
        },
        methods: {
            scrollTop: function () {
                this.intervalId = setInterval(() => {
                    if (window.pageYOffset === 0) {
                        clearInterval(this.intervalId)
                    }
                    window.scroll(0, window.pageYOffset - 50)
                }, this.speed)
            },
            scrollListener: function (e) {
                this.visible = window.scrollY > 150
            }
        },
        mounted: function () {
            window.addEventListener('scroll', this.scrollListener)
        },
        beforeDestroy: function () {
            window.removeEventListener('scroll', this.scrollListener)
        }
    }
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

    .bottom-right {
        position: fixed;
        bottom: 70px;
        right: 20px;
        cursor: pointer;
    }
</style>

