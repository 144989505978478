export const RegisterInputMixin = {
    methods: {
        check_register_input(email, username, password) {
            let success = true
            let errors_in_reg = {}
            if (email.length === 0) {
                success = false;
                errors_in_reg['email'] = ["Данное поле обязательно для заполнения."]
            } else if (!/^[а-яёa-z0-9_\-.]+@[а-яёa-z0-9\-]+\.[a-zа-яё0-9\-.]+$/isu.test(email)) {
                success = false;
                errors_in_reg['email'] = ["E-mail адрес некорректный."]
            }

            if (password.length === 0) {
                success = false;
                errors_in_reg['password'] = ["Данное поле обязательно для заполнения."]
            } else if (password.length < 8) {
                success = false;
                errors_in_reg['password'] = ["Количество символов должно быть не меньше 8."]
            }

            if (username.length === 0) {
                success = false;
                errors_in_reg['name'] = ["Данное поле обязательно для заполнения."]
            } else if (username.length < 3 || username.length > 16) {
                success = false;
                errors_in_reg['name'] = ["Количество символов должно быть между 3 и 16."]
            } else if (!/^([A-Z\d_]+)$/isu.test(username)) {
                success = false;
                errors_in_reg['name'] = ["Ник игрока может содержать только латинские буквы, цифры и нижнее подчеркивание."]
            } else if (username.replace(/\d+/g, '').length * 2 < username.length) {
                success = false;
                errors_in_reg['name'] = ["Ник игрока должен содержать как минимум 50% букв."]
            } else {
                ['admin', 'moder', 'helper', 'support'].forEach(value => {
                    if (username.toLowerCase().indexOf(value) >= 0) {
                        success = false;
                        errors_in_reg['name'] = ["Ник игрока не должен содержать слов admin, moder, helper и т.д."]
                    }
                })
            }
            if (success)
                return null
            return errors_in_reg
        }
    }
}