<template>
    <div>
        <header class="header">
            <div class="nav-c">
                <div class="nav-row"
                     :class="{'black__transparent_bg': $root.getScrollPosition() >= 50, 'transparent_bg': $root.getScrollPosition() < 50 }">
                    <div class="nav-lgwrapp">
                        <div class="nav-logo" @click="$root.redirect('/')"/>
                    </div>
                    <div class="header__ul">
                        <ul class="links">
                            <li>
                                <router-link to="/">
                                    Главная
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/rules">
                                    Правила
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/donat">
                                    Донат
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/banlist">
                                    Банлист
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/regen">
                                    Регенерация
                                </router-link>
                            </li>
                            <li>
                                <a href="https://wiki.greatray.ru/" target="_blank">
                                    Wiki
                                </a>
                            </li>
                            <li>
                                <a href="https://forum.greatray.ru/" target="_blank">
                                    Форум
                                </a>
                            </li>
                            <li>
                                <a href="https://discord.gg/9w2eArqqDm" target="_blank">
                                    Discord
                                </a>
                            </li>
                            <li>
                                <router-link to="/help">
                                    Помощь
                                </router-link>
                            </li>
                            <li v-if="$root.isModerator()" >
                                <router-link to="/moder" style="color:#4eff2f">
                                    Модер
                                </router-link>
                            </li>
                            <li v-if="$root.isAdmin() || $root.isServerAdmin()">
                                <router-link to="/admin" style="color:red">Админ</router-link>
                            </li>

                            <!-- TODO эту фигню также можно перенести в админку -->
                            <li>
                                <a v-if="$root.isAdmin() || $root.isServerAdmin()" href="https://plan.greatray.ru" target="_blank"
                                   style="text-decoration: none; color: #00e6ff">
                                    Plan
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="si-su-group" v-if="$root.getPlayerData() != null">
                        <div class="balance">
                            <a href=""> {{
                                    $root.getPlayerData().balance === undefined ? 0 :
                                        $root.getPlayerData().balance
                                }} ₽
                            </a>
                        </div>

                        <div class="btn-left" @click="logout">
                            <a>Выйти
                            </a>
                        </div>

                        <div class="btn-right">
                            <router-link to="/lk" style="text-decoration: none;">
                                {{ $root.getPlayerData().name }}
                            </router-link>
                        </div>

                    </div>

                    <div class="si-su-group" v-else>

                        <div class="btn-left" @click="login">
                            <a>Войти</a>
                        </div>
                        <div class="btn-right" @click="login">
                            <a>Регистрация</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    </div>
</template>

<script>
import AuthorizeModal from "../../modals/AuthorizeModal";
import {UAnimateContainer, UAnimate} from 'vue-wow'

export default {
    data() {
        return {
            logined: false,
            toggle_menu: false,
        }
    },
    name: "nav_panel",
    components: {
        UAnimateContainer,
        UAnimate
    },
    methods: {

        showMenu() {
            if (this.toggle_menu) {
                this.toggle_menu = false
            } else {
                this.toggle_menu = true
            }
        },

        logout() {
            const axios = require('axios');
            let imitate = this.$root.isImitator()
            axios.post('/api/auth/logout').then(data => {
                // console.log(data)
                if (imitate) {
                    console.log('Возвращаемся к основному аккаунту.')
                    if (this.$router.history.current["path"] === '/')
                        window.location.reload()
                    else
                        this.$root.redirect('/')
                } else {
                    console.log('Выходим из учётной записи.')
                }
            });
            if (!imitate) {
                localStorage.removeItem('player');
                this.$root.setPlayerData(null);
                this.$root.redirect('/')
            }
        },

        login() {
            this.$modal.show(
                AuthorizeModal,
                {text: 'This text is passed as a property'},
                {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 10px; box-shadow: none;",
                    width: "420px",
                    height: "auto",
                    // draggable: ".authorize_window",
                    // Resets position and size before showing
                    reset: true
                },
                {
                    'before-close': event => {
                    }
                }
            )
        }
    },
    created() {
        if (this.$root.getPlayerData() == null && document.cookie.indexOf('ips4_member_id') >= 0) {
            console.log('Чпок! Добрый вечер')
            this.$root.update_PlayerData()
        }
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.nav-c {
    height: auto;
    overflow: hidden;
    background: none;
}

.nav-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin-top: 0px;
    height: 50px;
    background: transparent;
    min-width: 1200px;
}

.nav-lgwrapp {
    @include flexible();
    min-width: 145px;
    height: auto;
}

.nav-logo {
    display: flex;
    background: url(../../../assets/img/logononcompressed.png) no-repeat;
    background-size: cover;
    min-width: 79px;
    height: 30px;
    cursor: pointer;
}

.links {
    display: inline-flex;
    list-style: none;
    width: 100%;
    font-family: $main-font;

    li {
        @include text-normalize();
        cursor: pointer;
        text-decoration: none;
        padding: 0 17px;
        font-size: 14px;
        margin: 0;
        color: rgba(255, 255, 255, 0.8);
        transition: all 0.2s ease-in-out;
        -webkit-user-select: none;

        a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.8);
            -webkit-user-select: none;
        }
    }
}

.links li:hover,
.links li a:hover {
    text-shadow: rgb(189, 153, 153) 0 0 15px;
    transform: scale(1.08);
    transition: all 0.2s ease-in-out;
}

.links li:active,
.links li a:active {
    text-shadow: rgba(165, 143, 225, 0.93) 0 0 15px;
    color: #9978ff;
    transform: scale(1.08);
    transition: all 0.05s ease-in-out;
}

.si-su-group {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: -2px;
    z-index: 110;
    font-family: $main-font;
}


.btn-left a {
    font-size: 0.9em;
    color: #878afa;
    text-decoration: none;
    font-family: $main-font;
    cursor: pointer;
    transition: none;
}

.btn-right {
    width: auto;
    height: auto;
    background-image: linear-gradient(to top, #3b3d90, #5f39c9, #522cb9), linear-gradient(to top, #1a1339, #5f36cf);
    font-size: 0.8em;
    padding: 4.5px 3px;
    white-space: nowrap;
    border-radius: 5px;
    cursor: pointer;
    font-family: $main-font;
    margin-left: 10px;
    -webkit-user-select: none;

    a {
        @include flexible();
        padding: 0 11px;
        font-size: 1.1em;
        @include text-normalize();
        line-height: 1;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
    }
}

.balance {
    margin-right: 17px;
}

.balance a {
    font-size: 0.85em;
    text-align: center;
    color: #FFF;
    text-decoration: none;
}

.black__transparent_bg {
    background: rgba(16, 16, 16, 0.84);
    transition: all 0.5s ease-in-out;
}

.transparent_bg {
    background: transparent;
    transition: all 0.5s ease-in-out;
}


</style>
