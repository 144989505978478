import { render, staticRenderFns } from "./NewArchNoMobile.vue?vue&type=template&id=25a7c288&scoped=true&"
import script from "./NewArchNoMobile.vue?vue&type=script&lang=js&"
export * from "./NewArchNoMobile.vue?vue&type=script&lang=js&"
import style0 from "./NewArchNoMobile.vue?vue&type=style&index=0&id=25a7c288&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a7c288",
  null
  
)

export default component.exports