<template>
    <div class="fcont">
        <div class="info">
            <div class="text">
                На данный момент проект переходит на новую архитектуру. <br><br> Мобильная версия сайта временно не доступна.
                <br><br> Приносим извинения за доставленные неудобства, мы стремимся сделать проект лучше.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewArchNoMobile"
}
</script>

<style scoped>
.fcont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.info {
    padding: 0 10vw;
}

.text {
    font-family: AventirN;
    font-size: 1.2em;
    color: white;
    text-align: center;
}
</style>