import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [{  // Главная страница проекта
    path: '/',
    name: 'Main',
    component: () => import( /* webpackChunkName: "main" */ '../views/Main')
}, {
    path: '/func-redirect/:OpeningLinkage',
    name: 'MainFancyRedirect',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main'),
    props: true
}, { // Страница платных услуг
    path: '/donat',
    name: 'Donat',
    component: () => import( /* webpackChunkName: "donat" */ '../views/Donat')
}, { // Страница для вывода новости TODO сделать вывод по id новости
    path: '/articles',
    name: 'Articles',
    component: () => import( /* webpackChunkName: "articles" */ '../views/Articles')
}, { // Правила
    path: '/rules',
    name: 'Rules',
    component: () => import( /* webpackChunkName: "rules" */ '../views/Rules')
}, { // Правила
    path: '/help',
    name: 'Help',
    component: () => import( /* webpackChunkName: "help" */ '../views/Help')
}, { // Правила
    path: '/banlist',
    name: 'BanList',
    component: () => import( /* webpackChunkName: "banlist" */ '../views/BanList')
}, { // Личный кабинет
    path: '/lk',
    name: 'lk',
    component: () => import( /* webpackChunkName: "lk" */ '../views/Lk')
}, { // Восстановление пароля
    path: '/reset-password/:token/:email',
    name: 'ResetPwd',
    component: () => import( /* webpackChunkName: "resetpwd" */ '../views/ResetPwd'),
    props: true,
}, {
    path: '/referal/:id',
    name: 'ReferalRedirect',
    component: () => import(/* webpackChunkName: "referalRedirect" */ '../views/ReferalRedirect'),
    props: true,
}, {
    path: '/play/:id',
    name: 'ReferalRedirect2',
    component: () => import(/* webpackChunkName: "referalRedirect" */ '../views/ReferalRedirect'),
    props: true,
}, {
    path: '/regen',
    name: 'Regen',
    component: () => import(/* webpackChunkName: "regen" */ '../views/Regen'),
    props: true,
}, {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin'),
    props: true,
}, {
    path: '/moder',
    name: 'Moder',
    component: () => import(/* webpackChunkName: "moder" */ '../views/Moder'),
    props: true,
}, {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound"*/ '../views/NotFound')
}, {
    path: '*',
    name: 'NotFoundRedirect',
    component: () => import(/* webpackChunkName: "NotFoundRedirect"*/ '../views/NotFoundRedirect')
}];

const router = new VueRouter({
    mode: 'history',
    routes,

    // Метод ниже сбрасывает позицию скроллера при переходе на другую страницу.
    scrollBehavior() {
        return {x: 0, y: 0};
    },
})

export default router
